import axios from "axios";
import { toast } from "react-toastify";
const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});
AxiosInstance.interceptors.request.use(
  function (config) {
    const insta = JSON.parse(localStorage.getItem("userInfo"));
    config.headers.token = insta?.token;
    config.headers.apikey = insta?.apikey;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

AxiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response)
    {
      const insta = JSON.parse(localStorage.getItem("userInfo"));
      if (insta)
      {
        localStorage.removeItem("userInfo");
        window.location.pathname = "/";
        toast.warn("Login session expired, please logi again.");
      } else
      {
        console.error("Response Error:", error.response);
      }
    } else if (error?.request)
    {
      console.error("Request Error:", error.request);
    } else
    {
      console.error("Error:", error.message);
    }
    return Promise.reject(error);
  }
);

export default AxiosInstance;
