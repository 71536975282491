import { Box, Typography } from "@mui/material";
import React from "react";
import NoDataImg from "../../../Common/NoData";
import { AppDomain } from "../../../Common/Constant";

import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FetchSignedUrl from "../../../Component/URL_Generator";

export default function Licence({ data, pdficon }) {
  return (
    <Box
      sx={{
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        width: "100%",
        maxHeight: { md: "calc(100vh - 350px)" },
        // minHeight: '350px',
        overflow: { md: "scroll" },
        borderRadius: "8px",
      }}
      className="HideScrollBar"
    >
      <Typography
        sx={{
          p: "1rem",
          background:
            "linear-gradient(181.45deg, #7AACFF -80.86%, rgba(60, 117, 213, 0.98) 98.77%)",
          color: "white",
          borderTopLeftRadius: "8px",
          height: "50px",
          borderTopRightRadius: "8px",
          position: "sticky",
          zIndex: 1,
          top: "0",
        }}
      >
        Licence ID
      </Typography>
      <Box sx={{ p: 2, height: "calc(100% - 50px)", overflow: "scroll" }}>
        {data?.licence_id?.length > 0 ? (
          <Box>
            {data?.licence_id &&
              data?.licence_id?.map((licid, i) => {
                const ext = licid.licence_file.split(".");
                const status = licid?.status;

                const newURL = licid?.licence_file?.split(
                  "https://instatrainme-api.s3.amazonaws.com/"
                )[1];
                const imgURL = FetchSignedUrl({ path: newURL });
                return (
                  status === 1 && (
                    <Box
                      sx={{
                        // p: 2,
                        borderRadius: "8px",
                        // boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        // alignItems: "center",
                        // justifyContent: "center",
                        // height: "calc(100% - 50px)",
                      }}
                      key={i}
                    >
                      <Typography> {licid?.licence_name}</Typography>
                      <Box
                        sx={{
                          p: 1,
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                          width: "200px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "8px",
                          gap: "0.25rem",
                        }}
                      >
                        <a
                          href={imgURL}
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            color: "black",
                            textDecoration: "none",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "0.25rem",
                            position: "relative",
                          }}
                        >
                          <InsertDriveFileIcon style={{ color: "#ea0606" }} />
                          {/* <img src={pdficon} alt="" style={{ width: "20px" }} />{" "} */}
                          <span
                            style={{
                              fontSize: "0.4rem",
                              color: "white",
                              position: "absolute",
                              paddingTop: "11px",
                              left: 5,
                              letterSpacing: "1px",
                            }}
                          >
                            {ext[ext?.length - 1]?.toUpperCase()}
                          </span>
                          <span
                            style={{ fontWeight: "500", fontSize: "14px" }}
                          >{`Licence ${i > 0 ? i : ""}`}</span>
                        </a>
                      </Box>
                      <hr
                        style={{
                          background: "#CECECE",
                          opacity: "0.3",
                          margin: "1rem 0 .5rem 0",
                          display:
                            data?.licence_id?.length - 1 !== i
                              ? "initial"
                              : "none",
                        }}
                      />
                    </Box>
                  )
                );
              })}
          </Box>
        ) : (
          <Box sx={{ padding: "1rem", width: "100%", height: "100%" }}>
            <NoDataImg />
          </Box>
        )}
      </Box>
    </Box>
    // </Box>
  );
}
