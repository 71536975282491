export const apiList = {
  // AUTH

  LOGIN: "/auth/admin-login",
  ForgotPassword: "/auth/forgot-password",
  //Dash
  Dashboard: "/admin/dashboard",
  //Booking
  GetBookingList: "/admin/booking-list",
  GetBookingDetailsById: "/admin/booking-detail",
  //Payment
  GetPaymentList: "/admin/get-payment-list",
  //user
  UserAction: "/admin/user-action",
  UserApproval: "/admin/user-approval",
  UserList: "/admin/user-list",
  UserDetailsById: "/admin/user-details",
  DeleteUser: "/admin/user-action",
  TrainersList: "/admin/trainer-list",
  TrainersDetails: "/admin/trainer-details",
  TrainersDetails_slots: "admin/trainer-details/slots",
  TrainersDetails1: "/admin/trainer-details_1",
  TrainersDetails2: "/admin/get-review-order-list",
  SendNotifications: "/notification/sent-multiple-notification",
  GetNotificationsList: "/notification/get-notification?user_type=3&u_id=0",
  getAppIssueList: "/admin/get-issue-list",
  //FAQ
  AddFAQs: "/admin/addfaq",
  FAQsList: "/admin/get-faq-list",
  //stripe
  updateCharge: '/admin/edit-charge',
  getStripeCharge: '/admin/get-charge',

  // AWS
  getAwsS3key: '/admin/get-key', 
  updateAwsS3key: '/admin/edit-key',

};
