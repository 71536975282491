import { Box, CircularProgress, IconButton, InputAdornment, TextField, useTheme } from "@mui/material";
import React, { useEffect, useRef } from "react";
import StripeChargeUpdateModal from "./StripeChargeUpdateModal";
import AxiosInstance from "../../Common/Api/ApiHelper";
import { apiList } from "../../Common/Api/ApiList";
import { useState } from "react";
import Loader from "../../Common/Loader";
import { toast } from "react-toastify";
import ErrorMsgComponent from "../../Component/Error_msg_Component";
import { Visibility, VisibilityOff } from "@mui/icons-material"
function SettingScreen() {
  const [data, setdata] = useState(0);
  const theme = useTheme();

  const [loading, setloading] = useState(true);
  const [loading1, setloading1] = useState(false);
  const [loading2, setloading2] = useState(false);
  const [isloading, setLoading] = useState(false);

  const [accesskey, setAccesskey] = useState("")
  const [secretkey, setSecretkey] = useState("")

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

const userInfoData = localStorage.getItem("userInfo")

  const togglePasswordVisibility = () => setShowPassword((prev) => !prev);
  const togglePasswordVisibility1 = () => setShowPassword1((prev) => !prev);
  const [error, seterror] = useState("");
  const [isSubmitted, setisSubmitted] = useState(false);
  const Ref = useRef(data);
  const fetchData = async () => {
    try
    {
      const res = await AxiosInstance.get(apiList.getStripeCharge);
      if (res.data.s === 1 && res.data.r.status === 1)
      {

        setdata(res.data.r.charge);
        Ref.current = res.data.r.charge
      } else
      {
        toast.error(res.data.m);
      }
      setloading(false);
    } catch (error)
    {
      setloading(false);
      console.log(error);
    }
  };

  const fetchAWSkeys = async () => {
    

    try
    {
        const res = await AxiosInstance.get(`${apiList.getAwsS3key}?type=1`);
      
      if (res.data.s === 1)
      {
        setAccesskey(res?.data?.r?.access_key || "");
        setSecretkey(res?.data?.r?.secret_key || "");
      } else
      {
        toast.error(res.data.m);
      }
      setloading(false);
    } catch (error)
    {
      setloading(false);
      console.log(error);
    }
  };

  const handleChange = (e) => {
    setisSubmitted(true);
    const { value } = e.target;
    if (value < 0)
    {
      setdata(0);
      seterror("");
    } else if (value < 100)
    {
      setdata(value);
      seterror("");
    } else if (value >= 100)
    {
      seterror("max limit exceeded.");
    }
  };

  const handleSubmit = async () => {
    try
    {
      setLoading(true);
      setisSubmitted(true);
      const res = await AxiosInstance.post(apiList.updateCharge, {
        charge: data,
      });
      if (res.data.s === 1)
      {
        await fetchData();
        // handleClose();
        toast.success("Updated successfully.");
        setLoading(false);
      } else
      {
        toast.error(res.data.m);
        setLoading(false);
      }
    } catch (error)
    {
      console.error("Error Stripe Charge update:", error);
      setLoading(false);
    }
  };

  const UpdateAWSkeys = async ( key ) => {
    try
    {
      let f = new FormData()
      f.append("type", 1)
      if (key === 2)
      {
        f.append("secret_key", secretkey)
        setloading2(true)
      } else
      {
        f.append("access_key", accesskey)
        setloading1(true)
      }
      const res = await AxiosInstance.post(apiList.updateAwsS3key, f);
      if (res.data.s === 1)
      {
        await fetchAWSkeys();
        toast.success("Updated successfully.");
        setloading1(false)
        setloading2(false)

      } else
      {
        toast.error(res.data.m);
        setloading1(false)
        setloading2(false)
      }
    } catch (error)
    {
      console.error("Error Stripe Charge update:", error);
      setloading1(false)
      setloading2(false)
    }
  };


  useEffect(() => {
    fetchData();
    fetchAWSkeys()
  }, []);
  return (
    <Box sx={{ height: "calc(100vh - 90px)", width: "100%" }}>
      {loading ? (
        <Loader />
      ) : (
        <> <Box>
          <Box
            sx={{
              width: { sm: "100%", md: "70%", lg: "50%", xl: '35%' },
              boxShadow: "3px 3px 7px 0 #00000040",
              overflow: "hidden",
              borderRadius: "9px",
              height: "fit-content",
              mt: 1.5,
            }}
          >
            <Box
              sx={{
                background: theme.palette.primary.Blend,
                width: "100%",
                color: "white",
                padding: "10px",
                borderTopLeftRadius: "9px",
                borderTopRightRadius: "9px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box>Stripe Charge</Box>
            </Box>
            <Box sx={{ display: "flex", width: "100%" }}>
              <Box
                sx={{
                  background: theme.palette.background.thead,
                  width: "30%",
                  padding: "10px",
                  borderBottom: "1px solid white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                Current (%)
              </Box>
              <Box
                sx={{
                  width: "70%",
                  padding: "10px",
                  borderBottom: `1px solid ${theme.palette.background.thead}`,
                  fontWeight: "600",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                {/* <Box>{data}%</Box>{" "} */}
                <TextField
                  type="number"
                  name="charge"
                  id="charge"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-"].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  error={isSubmitted && error}
                  placeholder="charge"
                  onChange={handleChange}
                  value={data}
                  onKeyUp={(key) =>
                    key.keyCode === 13 &&
                    (Ref.current !== data || isloading) &&
                    handleSubmit()
                  }
                  sx={{
                    width: "100%",
                    ".MuiInputBase-root": {
                      borderRadius: "8px",
                      padding: "4px 0",
                    },
                  }}
                />
                {isSubmitted && error && <ErrorMsgComponent error={error} />}
              </Box>
              <Box
                sx={{
                  background: theme.palette.background.thead,
                  width: "200px",
                  padding: "10px",
                  borderBottom: "1px solid white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <button
                  style={{
                    width: "100%",
                    fontFamily: "Helvetica",
                    borderRadius: "8px",
                    textTransform: "none",
                    fontWeight: "700",
                    fontSize: "1rem",
                    padding: "8px",
                    border: "none",
                    color: theme.palette.text.button,
                    background: theme.palette.primary.Blend,
                    cursor: Ref.current !== data && "pointer",
                  }}
                  disabled={isloading || Ref.current === data}
                  type="submit"
                  onClick={handleSubmit}
                >
                  {isloading ? <CircularProgress size={26} /> : "Update"}
                </button>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: { sm: "100%", md: "70%", lg: "50%", xl: '35%' },
              boxShadow: "3px 3px 7px 0 #00000040",
              overflow: "hidden",
              borderRadius: "9px",
              height: "fit-content",
              mt: 1.5,
            }}
          >
            <Box
              sx={{
                background: theme.palette.primary.Blend,
                width: "100%",
                color: "white",
                padding: "10px",
                borderTopLeftRadius: "9px",
                borderTopRightRadius: "9px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box>AWS Bucket</Box>
            </Box>
            <Box sx={{ display: "flex", width: "100%" }}>
              <Box
                sx={{
                  background: theme.palette.background.thead,
                  width: "30%",
                  padding: "10px",
                  borderBottom: "1px solid white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                Access Key
              </Box>
              <Box
                sx={{
                  width: "70%",
                  padding: "10px",
                  borderBottom: `1px solid ${theme.palette.background.thead}`,
                  fontWeight: "600",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                {/* <Box>{data}%</Box>{" "} */}
                <TextField
                  type={showPassword ? "text" : "password"}
                  name="accesskey"
                  id="accesskey"
                  inputMode="text"
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
                  }
                  error={isSubmitted && error}
                  placeholder="Password"
                  onChange={(e) => setAccesskey(e.target.value)}

                  value={accesskey}
                  // onKeyUp={(key) =>
                  //   key.keyCode === 13 &&
                  //   (refElement.current !== data || isLoading) &&
                  //   handleSubmit()
                  // }
                  sx={{
                    width: "100%",
                    ".MuiInputBase-root": {
                      borderRadius: "8px",
                      padding: "4px 0",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" >
                        <IconButton onClick={togglePasswordVisibility} edge="end" sx={{
                          padding: 0,
                          marginRight: "4px",
                        }}>
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ), style: { paddingRight: 0 },

                  }}
                />
                {isSubmitted && error && <ErrorMsgComponent error={error} />}
              </Box>
              <Box
                sx={{
                  background: theme.palette.background.thead,
                  width: "200px",
                  padding: "10px",
                  borderBottom: "1px solid white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <button
                  style={{
                    width: "100%",
                    fontFamily: "Helvetica",
                    borderRadius: "8px",
                    textTransform: "none",
                    fontWeight: "700",
                    fontSize: "1rem",
                    padding: "8px",
                    border: "none",
                    color: theme.palette.text.button,
                    background: theme.palette.primary.Blend,
                    cursor: "pointer",
                  }}
                  disabled={loading1}
                  type="submit"
                  onClick={() => UpdateAWSkeys(1)}
                >
                  {loading1 ? <CircularProgress size={26} /> : "Update"}
                </button>
              </Box>
            </Box>
            <Box sx={{ display: "flex", width: "100%" }}>
              <Box
                sx={{
                  background: theme.palette.background.thead,
                  width: "30%",
                  padding: "10px",
                  borderBottom: "1px solid white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                Secret Key
              </Box>
              <Box
                sx={{
                  width: "70%",
                  padding: "10px",
                  borderBottom: `1px solid ${theme.palette.background.thead}`,
                  fontWeight: "600",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                {/* <Box>{data}%</Box>{" "} */}
                <TextField
                  type={showPassword1 ? "text" : "password"}
                  name="password"
                  id="password"
                  inputMode="text"
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
                  }
                  error={isSubmitted && error}
                  placeholder="Password"
                  onChange={(e) => setSecretkey(e.target.value)}
                  value={secretkey}
                  // onKeyUp={(key) =>
                  //   key.keyCode === 13 &&
                  //   (refElement.current !== data || isLoading) &&
                  //   handleSubmit()
                  // }
                  sx={{
                    width: "100%",
                    ".MuiInputBase-root": {
                      borderRadius: "8px",
                      padding: "4px 0",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={togglePasswordVisibility1}
                          edge="end"
                          sx={{
                            padding: 0,
                            marginRight: "4px",
                          }}
                        >
                          {showPassword1 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: { paddingRight: 0 },
                  }}
                />
                {isSubmitted && error && <ErrorMsgComponent error={error} />}
              </Box>
              <Box
                sx={{
                  background: theme.palette.background.thead,
                  width: "200px",
                  padding: "10px",
                  borderBottom: "1px solid white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <button
                  style={{
                    width: "100%",
                    fontFamily: "Helvetica",
                    borderRadius: "8px",
                    textTransform: "none",
                    fontWeight: "700",
                    fontSize: "1rem",
                    padding: "8px",
                    border: "none",
                    color: theme.palette.text.button,
                    background: theme.palette.primary.Blend,
                    cursor: "pointer",
                  }}
                  disabled={loading2}
                  type="submit"
                  onClick={() => UpdateAWSkeys(2)}
                >
                  {loading2 ? <CircularProgress size={26} /> : "Update"}
                </button>
              </Box>
            </Box>
          </Box> </Box>
        </>
      )}

    </Box>
  );
}

export default SettingScreen;
