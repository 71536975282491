import AWS from "aws-sdk";

const getSignedUrl = async (accessKey, secretKey, bucket, region, objectKey) => {
    return new Promise((resolve, reject) => {
        // Configure AWS SDK
        AWS.config.update({
            accessKeyId: accessKey,
            secretAccessKey: secretKey,
            region: region,
        });

        const s3 = new AWS.S3({
            signatureVersion: 'v4', // Ensure signature version is set to v4
        });

        const params = {
            Bucket: bucket,
            Key: objectKey,
            Expires: 900, // URL expires in 15 minutes
        };

        s3.getSignedUrl("getObject", params, (err, url) => {
            if (err)
            {
                console.error("Error generating pre-signed URL", err);
                reject(err);
            } else
            {
                console.log("Generated URL: ", url); // Log the URL to inspect it
                resolve(url);
            }
        });
    });
};

export default getSignedUrl;
