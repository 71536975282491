
import { toast } from 'react-toastify';
import AxiosInstance from '../Common/Api/ApiHelper';
import { apiList } from '../Common/Api/ApiList';

export const fetchAWSKeysFromAPI = async () => {
    try
    {
        const res = await AxiosInstance.get(`${apiList.getAwsS3key}?type=1`);
        if (res.data.s === 1)
        {
            return {
                accessKey: res.data.r.access_key || '',
                secretKey: res.data.r.secret_key || '',
                bucket: res.data.r.bucket_name || '',
                region: res.data.r.region || ''
            };
        } else
        {
            toast.error(res.data.m);
            return null; // Return null if the API response is not successful
        }
    } catch (error)
    {
        console.error('Error fetching AWS keys:', error);
        return null; // Handle error and return null
    }
};
