import { Box } from "@mui/material";
import React, { useState } from "react";
import PaymentDropItemComponent from "./PaymentDropItemComponent";
import dayjs from "dayjs";
import PaymentList from "./PaymentListDataGrid";

const PaymentHistoryScreen = () => {
  const [paymentFilter, setPaymentFilter] = useState("All");
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection:"column",
          gap:"30px"
        }}
      >
        <Box sx={{ width: "100%" }}>
          <PaymentDropItemComponent paymentFilter={paymentFilter} setPaymentFilter={setPaymentFilter} setEndDate={setEndDate}
            endDate={endDate}
            startDate={startDate}
            setStartDate={setStartDate} />
        </Box>
        <Box sx={{width:"100%"}}> 
          <PaymentList paymentFilter={paymentFilter} endDate={endDate}
            startDate={startDate} />
        </Box>
      </Box>
    </>
  )
}

export default PaymentHistoryScreen