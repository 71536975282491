import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import NoDataImg from "../../Common/NoData";
import Loader from "../../Common/Loader";
import defaultImg from "../../Assets/image/dash/main/np.png";
import dummyimage from "../../Assets/image/user/9720009.jpg";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PathList } from "../../Common/Routes/Path";
import FetchSignedUrl from "../../Component/URL_Generator";

export default function NewUsersListComponent({ data, isloading }) {
  const navigate = useNavigate();
  return (
    <Box sx={{ width: "100%", height: "calc(100vh - 230px)" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          fontWeight: "600",
          padding: "0.5rem 0",
          boxSizing: "border-box",
          height: "40px",
        }}
      >
        <Box> New Users</Box>
        <Box>
          {/* <ListModal data={data} item={"Users"} /> */}
          <Button
            sx={{
              color: "black",
              fontWeight: "600",
              fontFamily: "Helvetica",
              textTransform: "none",
              padding: "0",
              fontSize: "16px",
            }}
            disabled={data?.length === 0 ? true : false}
            onClick={() => navigate(PathList.UserManagement)}
          >
            View All
          </Button>
        </Box>
      </Box>

      <Box sx={{ height: `calc(100vh - 270px)`, width: "100%" }}>
        {isloading ? (
          <Loader />
        ) : (
          <DataGrid
            rows={[...data]}
            columns={columns}
            hideFooter
            getRowId={(e) => e?.rowid}
            slots={{ noRowsOverlay: NoDataImg }}
            // columnHeaderHeight={0}
            pageSizeOptions={[10]}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            sx={{
              "& .MuiDataGrid-cell": {
                border: "0.1px solid #DDDDDD50", // Customize the border as needed
              },
            }}
          />
        )}
      </Box>
    </Box>
  );
}

const columns = [
  {
    field: "rowid",
    headerName: "Sr.",
    width: 80,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "id",
    headerName: "ID",
    width: 80,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "profile_img",
    headerName: "Profile",
    minWidth: 60,
    flex: 0.5,
    renderCell: (cell) => {
      const path = cell?.row.profile_img?.split(
        "https://instatrainme-api.s3.amazonaws.com/"
      )[1];
      const i = path && FetchSignedUrl({ path: path });
      return (
        <Box
          sx={{
            height: "100%",
            padding: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "17px",
          }}
        >
          <img
            src={cell?.row?.profile_img ? i : defaultImg}
            height={40}
            width={40}
            alt="Profile"
            style={{ borderRadius: "12px", objectFit: "cover" }}
          />
        </Box>
      );
    },
    align: "center",
    headerAlign: "center",
  },
  {
    field: "name",
    headerName: "Name",
    minWidth: 150,
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  // {
  //   field: "last_name",
  //   headerName: "Last name",
  //   width: 150,
  //   align: "center",
  //   headerAlign: "center",
  // },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    minWidth: 220,
    align: "center",
    headerAlign: "center",
  },
];
