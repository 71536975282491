// AWSContext.js
import React, { createContext, useState, useEffect } from 'react';
import { fetchAWSKeysFromAPI } from './AWSService';

export const AWSContext = createContext();

export const AWSProvider = ({ children }) => {
    const [accessKey, setAccessKey] = useState('');
    const [secretKey, setSecretKey] = useState('');
    const [bucket, setBucket] = useState('');
    const [region, setRegion] = useState('');
    const [loading, setLoading] = useState(true);

    let data = localStorage.getItem("userInfo")
    
    const initializeAWSKeys = async () => {
        const keys = await fetchAWSKeysFromAPI();
        if (keys)
        {
            setAccessKey(keys.accessKey);
            setSecretKey(keys.secretKey);
            setBucket(keys.bucket);
            setRegion(keys.region);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (data){
            initializeAWSKeys();
        }
    }, [data]);

    return (
        <AWSContext.Provider value={{ accessKey, secretKey, bucket, region, loading }}>
            {children}
        </AWSContext.Provider>
    );
};
