import { PathList } from "../../Common/Routes/Path";
import dashboardA from "../../Assets/image/dash/sb/dashA.svg";
import BookingA from "../../Assets/image/dash/sb/BookingA.svg";
import FAQActive from "../../Assets/image/dash/sb/faqA.svg";
import NotificatonA from "../../Assets/image/dash/sb/notiA.svg";
import userA from "../../Assets/image/dash/sb/userA.svg";
import expertA from "../../Assets/image/dash/sb/expertA.svg";
import appissueA from "../../Assets/image/dash/sb/appissueA.svg";
import setting from "../../Assets/image/dash/sb/Setting.svg";
import history from "../../Assets/image/dash/sb/hsitory.png"
export const NavMenuList = [
  {
    title: "Dashboard",
    icon: dashboardA,
    path: PathList.Dashboard,
    active: dashboardA,
  },
  {
    title: "Booking Details",
    active: BookingA,
    icon: BookingA,
    path: PathList.Booking,
  },
  {
    title: "User Management",
    icon: expertA,
    path: PathList.UserManagement,
    active: expertA,
  },
  {
    title: "Trainer Management",
    icon: userA,
    path: PathList.Trainer,
    active: userA,
  },
  {
    title: "Payment History",
    icon: history,
    path: PathList.PaymentHistory,
    active: history,
  },
  {
    title: "Notification",
    icon: NotificatonA,
    path: PathList.Notification,
    active: NotificatonA,
  },
  {
    title: "FAQ",
    icon: FAQActive,
    path: PathList.FAQs,
    active: FAQActive,
  },
  {
    title: "Application Issue",
    icon: appissueA,
    path: PathList.AppIssue,
    active: appissueA,
  },
  {
    title: "Setting",
    icon: setting,
    path: PathList.Setting,
    active: setting,
  },
];
