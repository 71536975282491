import { useState, useEffect, useContext } from "react";
import AWS from "aws-sdk";
import { AWSContext } from "../GlobalContext/AWSContext";

const FetchSignedUrl = ({ path, onUrlGenerated }) => {
    const [signedUrl, setSignedUrl] = useState("");

    // const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
    // const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
    // const region = "us-east-1";
    // const bucketName = "instatrainme-api";
    const objectKey = path;
    const { accessKey, secretKey, bucket, region } = useContext(AWSContext);

    useEffect(() => {
        AWS.config.update({
            accessKeyId: accessKey,
            secretAccessKey: secretKey,
            region: region,
        });

        const s3 = new AWS.S3();

        const params = {
            Bucket: bucket,
            Key: objectKey,
            Expires: 900,
        };

        s3.getSignedUrl("getObject", params, (err, url) => {
            if (err)
            {
                console.error("Error generating pre-signed URL", err);
            } else
            {
                setSignedUrl(url);
                if (onUrlGenerated)
                {
                    onUrlGenerated(url);
                }
            }
        });
    }, [
        path,
        accessKey,
        secretKey,
        region,
        bucket,
        objectKey,
        onUrlGenerated,
    ]);

    return signedUrl;
};

export default FetchSignedUrl;

