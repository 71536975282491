import {
  Box,
  Checkbox,
  MenuItem,
  Select,
  TablePagination,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import searchicon from "../../Assets/image/dash/Minimalistic Magnifer.png";
import rateicon from "../../Assets/image/Trainers/Vector.png";
import AxiosInstance from "../../Common/Api/ApiHelper";
import dummyimage from "../../Assets/image/user/9720009.jpg";
import { toast } from "react-toastify";
import NoDataImg from "../../Common/NoData";
import Loader from "../../Common/Loader";
import debounce from "lodash.debounce";
import order from "../../Assets/image/dash/Record Circle.png";
import { apiList } from "../../Common/Api/ApiList";
import ErrorToaster from "../../Component/ErrorToaster";
import viewicon from "../../Assets/image/user/223_frame.png";
import { PathList } from "../../Common/Routes/Path";
import { useNavigate } from "react-router-dom";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { AppDomain } from "../../Common/Constant";
import { useCallback } from "react";
import FetchSignedUrl from "../../Component/URL_Generator";

const typeOptionList = [
  { status: "All", key: "all" },
  { status: "Virtual", key: "is_virtual" },
  { status: "User place", key: "user_place" },
  { status: "Trainer place", key: "trainer_place" },
];

const PAGE_SIZE_OPTIONS = [30];
const TrainerManagementScreen = () => {
  const theme = useTheme();
  const [user, setUser] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState(2);
  const [type, setType] = useState(["all"]);
  const [page, setPage] = useState(0);
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const [totalData, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [ratingRange, setratingRange] = useState("All");

  const fetchUserData = async (
    search = "",
    status,
    page = 0,
    pageSize = PAGE_SIZE_OPTIONS[0],
    previousSearch = "",
    previousStatus = 2,
    type = [],
    prevType = [],
    ratingRange = "All",
    prevRatingRange = "All"
  ) => {
    try
    {
      setloading(true);
      let newPage = page;
      if (
        search !== previousSearch ||
        status !== previousStatus ||
        type !== prevType ||
        ratingRange !== prevRatingRange
      )
      {
        newPage = 0;
        setPage(0);
      }
      let queryParams = new URLSearchParams({
        count: newPage * pageSize,
      });

      if (search.length > 0)
      {
        queryParams.append("search", search);
      }
      if (status !== 2)
      {
        queryParams.append("status", status);
      }
      if (!type.includes("all"))
      {
        type.map((x) => queryParams.append(x, 1));
      }

      if (!(ratingRange === "All"))
      {
        queryParams.append("rat_filter", ratingRange);
      }
      const api = `${apiList.TrainersList}?${queryParams.toString()}`;
      const response = await AxiosInstance.get(api);
      const Rowdata = response?.data?.r?.data?.map((data, i) => ({
        rowid: i + 1,
        ...data,
      }));
      
      if (Rowdata?.length > 0)
      {
        setUser(Rowdata);
        setTotalData(response?.data?.r?.total_count);
      } else
      {
        if (newPage === 0)
        {
          setUser([]);
        }
        setTotalData(0);
      }
      setIsLoading(false);
      setloading(false);
    } catch (error)
    {
      <ErrorToaster error={error} />;
      setloading(false);
      setIsLoading(false);
    }
  };

  const handleChange = useCallback(
    (event) => {
      const {
        target: { value },
      } = event;
      const selectedValues = value.filter((val) => val !== "all");

      if (value.includes("all"))
      {
        if (type.includes("all"))
        {
          setType([...selectedValues]);
        } else
        {
          setType(typeOptionList.map((item) => item.key));
        }
      } else
      {
        if (type.includes("all"))
        {
          setType([]);
        } else
        {
          setType(
            selectedValues.length === typeOptionList.length - 1
              ? [...selectedValues, "all"]
              : selectedValues
          );
        }
      }
    },
    [type, typeOptionList, setType]
  );

  const renderValue = useCallback(
    (selected) => {
      const selectedLabels = selected.map((key) => {
        const selectedItem = typeOptionList.find((item) => item.key === key);
        return selectedItem ? selectedItem.status : "";
      });

      if (selectedLabels.includes("All"))
      {
        return "All";
      } else if (selectedLabels.length > 1)
      {
        return selectedLabels.join(", ");
      } else
      {
        return selectedLabels;
      }
    },
    [typeOptionList]
  );

  const handleAction = async (id, status) => {
    try
    {
      setIsActionLoading(true);
      const res = await AxiosInstance.post(`${apiList.UserApproval}`, {
        id: id,
        status: status,
      });
      if (res?.data?.s === 1)
      {
        toast.success(res.data.m);
        fetchUserData("", "", 0);
      } else
      {
        toast.error(res.data.m);
      }
      setIsActionLoading(false);
    } catch (error)
    {
      <ErrorToaster error={error} />;
      setIsActionLoading(false);
    }
  };

  const handleRatingChange = (e) => {
    setratingRange(e?.target?.value);
  };

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Debounced fetch function
  const debouncedFetchUserData = debounce(
    (
      search,
      status,
      page,
      rowsPerPage,
      previousSearch,
      previousStatus,
      type,
      prevType,
      ratingRange,
      prevRatingRange
    ) =>
      fetchUserData(
        search,
        status,
        page,
        rowsPerPage,
        previousSearch,
        previousStatus,
        type,
        prevType,
        ratingRange,
        prevRatingRange
      ),
    300
  );

  const searchTermRef = useRef(searchTerm);
  const statusRef = useRef(status);
  const typeRef = useRef(type);
  const ratingRef = useRef(ratingRange);

  useEffect(() => {
    debouncedFetchUserData(
      searchTerm,
      status,
      page,
      rowsPerPage,
      searchTermRef.current,
      statusRef.current,
      type,
      typeRef.current,
      ratingRange,
      ratingRef.current
    );
    searchTermRef.current = searchTerm;
    statusRef.current = status;
    typeRef.current = type;
    ratingRef.current = ratingRange;
    return () => {
      debouncedFetchUserData.cancel();
    };
  }, [searchTerm, status, page, rowsPerPage, type.length, type, ratingRange]);

  const columns = [
    {
      headerClassName: "super-app-theme--header",
      field: "rowid",
      headerName: "Sr.",
      width: 80,
      headerAlign: "center",
      renderCell: (params) => {
        return params.row.rowid + page * PAGE_SIZE_OPTIONS ;
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "id",
      headerName: "ID",
      width: 80,
      align: "center",
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "profile_img",
      headerName: "Profile",
      width: 100,
      headerAlign: "center",
      renderCell: (e) => {
        const path = e?.row?.profile_img?.split(
          "https://instatrainme-api.s3.amazonaws.com/"
        )[1];
        const i =  FetchSignedUrl({ path: path });
        return (
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {e?.row?.profile_img ? (
              <img
                src={i}
                alt=""
                style={{
                  height: "40px",
                  width: "50px",
                  borderRadius: "10px",
                  border: "1px solid #E0E0E0",
                  objectFit: "cover",
                }}
              />
            ) : (
              <img
                src={dummyimage}
                alt=""
                style={{
                  height: "40px",
                  width: "50px",
                  borderRadius: "10px",
                  border: "1px solid #E0E0E0",
                }}
              />
            )}
          </Box>
        );
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "email",
      headerName: "Email",
      type: "number",
      flex: 1,
      minWidth: 250,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "rating_point",
      headerName: "Rate",
      type: "string",
      width: 150,
      headerAlign: "center",
      renderCell: (e) => {
        return (
          <Box
            sx={{
              gap: "2px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            {e?.row?.rating_point > 0 ? (
              <>
                <Typography>{e?.row?.rating_point}</Typography>
                <img src={rateicon} alt="" style={{ marginBottom: "3px" }} />
              </>
            ) : (
              "-"
            )}
          </Box>
        );
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "trainer_place",
      headerName: "Type",
      width: 220,
      headerAlign: "center",
      renderCell: (cell) => {
        const { is_virtual, trainer_place, user_place } = cell.row;
        const renderTag = (text) => (
          <span
            style={{
              background: "#7AACFF",
              color: "white",
              borderRadius: "5px",
              margin: "3px",
              padding: "5px 8px",
            }}
          >
            {text}
          </span>
        );

        return (
          <Box>
            {is_virtual && trainer_place && user_place ? (
              renderTag("All Types")
            ) : !is_virtual && !trainer_place && !user_place ? (
              "-"
            ) : (
              <>
                {trainer_place ? renderTag("Trainer Place") : ""}
                {user_place ? renderTag("User Place") : ""}
                {is_virtual ? renderTag("Virtual") : ""}
              </>
            )}
          </Box>
        );
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "trained_people",
      headerName: "Trained People",
      type: "string",
      width: 150,
      headerAlign: "center",
      renderCell: (e) => {
        return (
          <Box
            sx={{
              gap: "2px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            {<Typography>{e?.row?.trained_people}</Typography>}
          </Box>
        );
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "status",
      headerName: "Status",
      type: "string",
      width: 200,
      headerAlign: "center",
      renderCell: (e) => {
        const { status } = e.row;
        const statusStyle = {
          fontWeight: "500",
          opacity: "0.7",
          borderRadius: "10px",
          width: "100%",
          border: "1px solid",
          height: "70%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        };

        const boxStyle = {
          width: "100px",
          margin: "0 auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        };
        if (status === 1)
        {
          return (
            <Box sx={boxStyle}>
              <span
                style={{
                  ...statusStyle,
                  color: "green",
                  borderColor: "green",
                }}
              >
                Active
              </span>
            </Box>
          );
        } else if (status === -1)
        {
          return (
            <Box sx={boxStyle}>
              <span
                style={{
                  ...statusStyle,
                  color: "grey",
                  borderColor: "grey",
                }}
              >
                Restricted
              </span>
            </Box>
          );
        } else
        {
          return (
            <Box sx={boxStyle}>
              <span
                style={{
                  ...statusStyle,
                  color: "red",
                  borderColor: "red",
                }}
              >
                Deleted
              </span>
            </Box>
          );
        }
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "is_admin_approval",
      headerName: "Action",
      type: "string",
      width: 180,
      headerAlign: "center",
      renderCell: (e) => {
        const { is_admin_approval, id } = e.row;
        if (is_admin_approval === 0)
        {
          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
                gap: "1.5rem",
              }}
            >
              <CheckRoundedIcon
                onClick={() => {
                  !isActionLoading && handleAction(id, 1);
                }}
                sx={{
                  color: "green",
                  opacity: "0.7",
                  "&:hover": {
                    outline: "1px solid ",
                    borderRadius: "15px",
                  },
                }}
              />
              <ClearRoundedIcon
                onClick={() => {
                  !isActionLoading && handleAction(id, -1);
                }}
                sx={{
                  color: "red",
                  opacity: "0.7",
                  "&:hover": {
                    outline: "1px solid ",
                    borderRadius: "15px",
                  },
                }}
              />
            </Box>
          );
        } else if (is_admin_approval === 1)
        {
          return (
            <span
              style={{
                color: "green",
                fontWeight: "500",
                opacity: "0.7",
                letterSpacing: "0.025rem",
                // textTransform: "uppercase",
              }}
            >
              {/* Confirmed */}
              Approved
            </span>
          );
        } else if (is_admin_approval === -2)
        {
          return (
            <span
              style={{
                color: "green",
                fontWeight: "500",
                opacity: "0.7",
                letterSpacing: "0.025rem",
                // textTransform: "uppercase",
              }}
            >
              Deleted
            </span>
          );
        } else
        {
          return (
            <span
              style={{
                color: "red",
                fontWeight: "500",
                opacity: "0.7",
                letterSpacing: "0.025rem",
                // textTransform: "uppercase",
              }}
            >
              Rejected
            </span>
          );
        }
      },
    },

    {
      headerClassName: "super-app-theme--header",
      headerName: "View",
      sortable: false,
      width: 100,
      headerAlign: "center",
      renderCell: (e) => (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={viewicon}
            alt=""
            onClick={() => navigate(`${PathList.TrainerDetails}/${e?.row?.id}`)}
          />
        </Box>
      ),
    },
  ];

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          gap: "1rem",
          justifyContent: "space-between",
          alignItems: "end",
        }}
      >
        {/* Search Input */}
        <Box
          sx={{
            width: "350px",
            height: "45px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "10px",
              fontSize: "14px",
              boxShadow: " rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              border: "none",
              outline: "none",
              paddingLeft: "20px",
            }}
          />
          <span
            style={{
              position: "absolute",
              right: "0",
              paddingRight: "15px",
              paddingTop: "5px",
            }}
          >
            <img src={searchicon} alt="Search" />
          </span>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <Box
            sx={{
              padding: "8px",
              width: { xs: "100%", md: "110px" },
              borderRadius: "8px",
              fontSize: "14px",
              boxShadow: "0 3px 10px 0 rgba(0, 0, 0, 0.1)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                gap: "0.5rem",
                pb: "0.15rem",
                color: theme.palette.text.primary,
              }}
            >
              <img src={order} height={17} width={17} alt="" srcSet="" />
              Rating
            </Box>

            <Select
              value={ratingRange}
              fullWidth
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "8px",
                ".MuiSelect-select": {
                  padding: "8px 12px",
                },
              }}
              onChange={handleRatingChange}
            >
              {[
                {
                  // icon: false,
                  label: "All",
                },
                {
                  // icon: true,
                  label: "5",
                },
                {
                  // icon: true,
                  label: "4",
                },
                {
                  // icon: true,
                  label: "3",
                },
                {
                  // icon: true,
                  label: "2",
                },
                {
                  // icon: true,
                  label: "1",
                },
              ].map((item, i) => (
                <MenuItem key={i} value={item.label}>
                  {/* <Checkbox
                    style={{ color: "#7AACFF" }}
                    checked={type.includes(item.key) || type.includes("all")}
                  /> */}
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    {item.label}
                    {/* <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      {item.icon === true && (
                        <StarRateRoundedIcon
                          style={{
                            color: "#FFA534",
                            fontSize: "18px",
                          }}
                          key={`full-${i}`}
                        />
                      )}
                    </Box> */}
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box
            sx={{
              padding: "8px",
              width: { xs: "100%", md: "150px", lg: "180px", xl: "200px" },
              borderRadius: "8px",
              fontSize: "14px",
              boxShadow: "0 3px 10px 0 rgba(0, 0, 0, 0.1)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                gap: "0.5rem",
                pb: "0.15rem",
                color: theme.palette.text.primary,
              }}
            >
              <img src={order} height={17} width={17} alt="" srcSet="" />
              Type
            </Box>

            <Select
              value={type}
              fullWidth
              multiple
              renderValue={renderValue}
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "8px",
                ".MuiSelect-select": {
                  padding: "8px 12px",
                },
              }}
              onChange={handleChange}
            >
              {typeOptionList.map((item, i) => (
                <MenuItem
                  key={item.key}
                  value={item.key}
                // onClick={() => setClickedTypeItem(item?.key)}
                >
                  <Checkbox
                    style={{ color: "#7AACFF" }}
                    checked={type.includes(item.key) || type.includes("all")}
                  />
                  {item.status}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box
            sx={{
              padding: "8px",
              width: { xs: "100%", md: "150px", lg: "180px", xl: "200px" },
              borderRadius: "8px",
              fontSize: "14px",
              boxShadow: "0 3px 10px 0 rgba(0, 0, 0, 0.1)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                gap: "0.5rem",
                pb: "0.15rem",
                color: theme.palette.text.primary,
              }}
            >
              <img src={order} height={17} width={17} alt="" srcSet="" />
              Status
            </Box>

            <Select
              value={status}
              fullWidth
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "8px",
                ".MuiSelect-select": {
                  padding: "8px 12px",
                },
              }}
              onChange={(e) => setStatus(e.target.value)}
            >
              {[
                {
                  id: 2,
                  status: "All",
                },
                {
                  id: 0,
                  status: "Pending",
                },
                {
                  id: -1,
                  status: "Rejected",
                },
                {
                  id: 1,
                  // status: "Confirmed",
                  status: "Approved",
                },

                {
                  id: 4,
                  status: "Restricted",
                },
                {
                  id: 3,
                  status: "Deleted",
                },
              ].map((x, i) => {
                return (
                  <MenuItem key={i} value={x?.id} style={{ display: "block" }}>
                    {x?.status}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          // minHeight: "550px",
          height: "calc(100vh - 200px)",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          borderRadius: "5px",
          mt: 3,
          p: 3,
        }}
      >
        <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
          Trainers List
        </Typography>
        {/* DataGrid with filtered data */}
        {/* <Box sx={{ height: "500px" }}> */}
        {isLoading ? (
          <Loader />
        ) : (
          <Box
            sx={{
              height: "calc(100% - 50px)",
            }}
          >
            <DataGrid
              rows={user}
              rowCount={-1}
              pagination
              paginationMode="server"
              disableColumnMenu={true}
              // disableColumnSorting={true}
              disableAutosize={true}
              disableColumnFilter={true}
              disableRowSelectionOnClick={true}
              getRowId={(e) => e?.rowid}
              hideFooter
              loading={loading}
              slots={{ noRowsOverlay: NoDataImg }}
              columns={columns}
              sx={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                ".MuiDataGrid-cell": { textAlign: "center" },
                ".MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "600",
                  color: "white",
                },
                "& .super-app-theme--header": {
                  background: theme.palette.primary.Blend,
                },
              }}
              pageSizeOptions={[10]}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
            />
            <TablePagination
              component="div"
              count={totalData}
              page={page}
              onPageChange={handlePageChange}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleRowsPerPageChange}
              rowsPerPageOptions={PAGE_SIZE_OPTIONS}
            />
          </Box>
        )}
        {/* </Box> */}
      </Box>
    </>
  );
};

export default TrainerManagementScreen;
